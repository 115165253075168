body {
    min-height: auto;
    overflow: hidden;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#container {
    position: relative;
    width: 500px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.video-container {
    position: relative;
    width: 40%;
    height: 50%;
    overflow: hidden;
    border-radius: 50% / 50%;
}
video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 270%;
    height: 270%;
    transform: translate(-50%, -50%) scaleX(-1);
    clip-path: ellipse(50% 25% at 50% 50%);
}
.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 300px;
    transform: translate(-50%, -50%);
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    border-radius: 50% / 50%;
    pointer-events: none;
}

.button-container {
    margin-top: 20px;
}
.loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #000;
}

.center-text {
    padding: 10px 20px;
    text-align: center;
    margin: 0;
}

.center-text h1 {
    font-size: 1.5rem;
}

.video-container p {
    margin: 0;
    font-size: 1.2rem;
}
